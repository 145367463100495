import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import BreguetCategoryBtns from '../../components/preowned/BreguetCategoryBtns'

// markup
const BreguetMain = () => {
  const data = useStaticQuery(
    graphql`
      query queryBreguetMain {
        products: allStrapiProduct(
          filter: { brand: { eq: "Breguet" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Breguet Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/breguet/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Breguet watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/breguet/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-breguet-header.png"
                alt="Pre-Owned Certified Used Breguet Watches Header"
                aria-label="Used Breguet Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Breguet Watches</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE BREGUET WATCHES AT GRAY AND
              SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED BREGUET WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              <br />
              The best place to inquire about Breguet watches is Gray and Sons jewelers in South
              Florida. We specialize in all Breguet services such as buying Breguet watches, selling
              Breguet watches, trading-in Breguet watches, and repairing Breguet watches. Speak to a
              live representative online today via our <a href="/chat-with-rich">LIVE CHAT</a>{' '}
              feature on <a href="/">www.grayandsons.com</a> about all Breguet watches! Clients
              around the cities of Miami such as Sunny Isles, Haulover, Aventura, Hollywood, Fort
              Lauderdale, Miami Beach, Coconut Grove, Key Biscayne, and Bal Harbour should visit our
              Surfside based store for all certified authentic pre-owned Breguet watch needs.
              <br />
              <br />
              Since 1980, Gray and Sons Jewelers has been the ideal jeweler clients visit to ‘sell
              my certified pre-owned Breguet watch’ or to ‘buy a certified authentic Breguet watch’.
              Gray and Sons guarantee all their Breguet watches to be certified authentic and
              serviced ready-to-wear with a 1-year warranty on the movement. We back this up with
              our six in-house swiss-trained watchmakers who have a combined experience of over 150
              years in watchmaking.
              <br />
              <br />
              People looking for a place to “sell my pre-owned watch near me” or to “get the best
              price for my used watch?” are directed to Gray and Sons Jewelers. We have a department
              dedicated to buying from the public called Sell Us Your Jewelry. Anyone looking to
              sell pre-owned Breguet watches for the best price should submit images of their watch
              on
              <a href="https://sellusyourjewelry.com/">Sell Us Your Jewelry </a> to receive a free
              quote today! We have Breguet experts in-house to value your watch now!
              <br />
              <br />
              Clients around the nation rely on Sell Us Your Jewelry for the best deals on selling
              their pre-owned and genuine Breguet watches. We often do business with clients all
              around the country including states such as New York, California, Nevada, Illinois,
              Texas, Colorado, Tennessee, and many more!
              <br />
              <br />
              The name Breguet is highly acclaimed by any watch enthusiast and with grand reason.
              Breguet is the innovative mastermind behind several technological advancements in the
              history of watchmaking. Breguet watches have a distinguished aesthetic made up of
              lustful details and elements unique to them; one of them being the famous
              Engine-turned dials. This beautiful method of decorating the dial is a tradition
              Breguet has held since the beginning. Breguet happens to be one of the watchmaking
              grandfathers of innovation because of its continuous innovation.
              <br />
              <br />
              One example of these advances is their 1790 pare-chute system which allowed timepieces
              to absorb shocks without compromising the accuracy and integrity of the mechanism’s
              balance wheels. Breguet became one of the first to create a shock-proof timepiece.
              Later in 1795, they invented the Tourbillon escapement which later became patented by
              Breguet in 1801. The tourbillon escapement introduced a solution to a movement’s worst
              enemy, gravity. Breguet located all watch parts most sensitive to gravity inside a
              carriage which is constantly rotating, regulating any horological discrepancies.
              <br />
              <br />
              Breguet watches come in precious metals such as 14k and 18k yellow and rose and white
              gold as well as stainless steel. His and Hers models are made in all sizes for most
              collections. Breguet watches come in all shapes and sizes from 25mm to 41mm. Breguet
              is one of the few luxury watch brands whose vintage watches are as highly desired as
              their modern watches.
              <br />
              <br />
              Popular Pre-Owned Breguet Watch References are: Reference 1801BB/12/2W6, Reference
              3055BA, Reference 3130bb/11/986, Reference 3137bb/11/986, Reference 3300BA/12/286,
              Reference 3355OR, Reference 3380ba, Reference 3460ba/12/a90, Reference 3477pt/1E/986,
              Reference 3637BA/12/986, Reference 3657PT129V6, Reference 3680br/11/986, Reference
              3757PT/ 1e/9v6, Reference 3800plat.
              <br />
              <br />
              Clients looking to get a better deal on pre-owned Breguet watches should visit Gray
              and Sons Jewelers. At G&amp;S, we carry all kinds of used Breguet watches including
              discontinued Breguet and collectible Breguet watches. Vintage Breguet and Modern
              Breguet watches are sold and bought weekly by Gray and Sons from clients all around
              the nation.
              <br />
              <br />
              All around the world, Breguet watches are recognized and respected as fine swill
              luxury timepieces. Clients who want to add a Breguet watch to their collection should
              only trust that Gray and Sons Jewelers is the best new and used Breguet watch
              specialist. Here at Gray and Sons Jewelers, we have six Swiss-trained watchmakers with
              over 150 years of combined experience who fully service every single Breguet watch in
              our inventory.
              <br />
              <br />
              We provide repair services to the public as well as provide a 1-year warranty for all
              Breguet watches sold at Gray and Sons. In-house we offer the following services:
              cleaning, polishing, overhaul, and lubrication of the movement, battery changes,
              waterproofing, and much more! Come visit our store to repair my Breguet watch.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/breguet/">
                <button>SHOP BREGUET WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK BREGUET WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <BreguetCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default BreguetMain
